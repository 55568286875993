export default {
  data() {
    return {
      policy: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        tempContract: {},
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          module: "",
          showPolicy: "",
          searchTerm: "",
          order: "ascend",
          orderBy: "created",
        },
        meta: {

        }
      },
      loadingNewSale: false,
      createSale: false,

    };
  },
  methods: {
    changePoliciesPage(current) {
      this.policy.pagination.page = current;
      this.getPolicies(current);
    },
    changePoliciesPageSize(current, pageSize) {
      this.policy.pagination.page = current;
      this.policy.pagination.perPage = pageSize;
      this.getPolicies();
    },
    policyTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.policy.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.policy.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getPolicies();
    },
    onSaleSelected(id) {
      this.policy.details = null;
      this.createSale = false;
      this.getSale(id);
    },
    getSale(id) {
      this.$http
        .post("/policy/details?id=" + id)
        .then(({
          data
        }) => {
          this.loadingNewSale = false;
          this.policy.details = data;

          document.title = `VENDA ${data.id} - ${data.customer.first_name}  ${data.customer.last_name} `

          // CONTRATO TEMPORARIO
          this.policy.tempContract = {
            ...data.contract,
            package_type: data.contract.package_type ? data.contract.package_type : 'Serviços',
            the_customer: data.customer
          };

          let allFieldsSize = Object.values(data.contract.meta).length;
          let values = Object.values(data.contract.meta);
          let keys = Object.keys(data.contract.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.policy.tempContract[keys[i]] = values[i]
          }

          delete this.policy.tempContract.meta;

          this.policyForm.setFieldsValue(data)

        })
        .catch(
          ({
            response
          }) => {
            response
          }
        )
    },
    getPolicies(samePage) {
      this.policy.loading = true;

      let filters = "";

      // if (!this.$root.isAdmin()) {
      //   filters += `&user_id=${this.$store.state.userData.id}`;
      // } else {
        if (this.policy.filters.users.selected.length > 0) {
          filters += `&user_id=${ this.policy.filters.users.selected}`;
        }

        if (this.policy.filters.period.selected.length > 0) {
          filters += `&period=${this.policy.filters.period.selected[0]}|${this.policy.filters.period.selected[1]}`;
        }
        if (this.policy.filters.id) {
          filters += `&module_id=${ this.policy.filters.id }`;
        }

        if (this.policy.filters.module) {
          filters += `&module=${ this.policy.filters.module }`;
        }

        if (this.policy.filters.showPolicy) {
          filters += `&show_policy=${  this.policy.filters.showPolicy  }`;
        }

        if (this.policy.filters.searchTerm) {
          filters += `&s=${ this.policy.filters.searchTerm}`;
        }

      //}

      filters += `&order=${ this.policy.filters.order}&order-by=${ this.policy.filters.orderBy}`;

      this.$http
        .get(
          `/policy/list?page=${this.policy.pagination.page}&per_page=${this.policy.pagination.perPage}${filters}`
        )
        .then(({
          data
        }) => {
          if (!samePage) {
            this.policy.pagination.page = 1;
          }
          this.policy.pagination.total = data.meta.total;
          this.policy.pagination.totalPages = data.meta.total_pages;

          this.policy.list = data.data;
          this.policy.meta = data.meta;

          this.policy.loading = false;
          this.excelFile.data = data.data;
        })
        .catch(({
          response
        }) => {
          this.policy.pagination.total = response.data.meta.total;
          this.policy.pagination.totalPages = response.data.meta.total_pages;

          this.policy.list = [];
          this.policy.loading = false;
        });

    }
  }
}