<template>
  <div>
    <div v-if="policy.list.length > 0">
      <a-row
        v-for="(policy, index) in policy.list"
        :key="index"
        class="mb-40"
        :gutter="20"
      >
        <a-col :span="23">
          <div class="field title mb-10 mt-10">
            <label>Título da política</label>
            <a-input
              placeholder="Título da política"
              v-model="policy.raw.title"
              @blur="updatePolicy(policy.raw, index)"
            >
            </a-input>
          </div>

          <div class="field editor">
            <VueEditor
              v-model="policy.raw.content"
              :editor-toolbar="customToolbar"
              placeholder="Digite o texto aqui"
              @blur="updatePolicy(policy.raw, index)"
            />
          </div>

          <div class="field show">
            <a-checkbox
              class="mb-20"
              v-model="policy.raw.show_policy"
              style="font-size: 12px; font-weight: 500; position: relative"
              @change="updatePolicy(policy.raw, index)"
            >
              Marque para exibir no contrato
            </a-checkbox>
          </div>
        </a-col>
        <a-col :span="1">
          <a-button
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            style="margin-top: 8px"
            @click="deletePolicy(policy.raw)"
          >
          </a-button>
        </a-col>
      </a-row>

      <div class="action">
        <center>
          <a-button :loading="createLoading" @click="addPolicy" type="primary">
            Adicionar política
          </a-button>
        </center>
      </div>
    </div>
    <div v-if="policy.list.length == 0">
      <a-result
        status=""
        title=""
        sub-title="Nenhuma política cadastrada até o momento."
      >
        <template #icon>
          <img
            src="../../assets/images/dashboard/contracts/no-policy.png"
            width="100"
        /></template>

        <template #extra>
          <a-button :loading="createLoading" @click="addPolicy" type="primary">
            Adicionar política
          </a-button>
        </template>
      </a-result>
    </div>
    <draggable />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";
import policyMixins from "@/mixins/policies/policyMixins";

export default {
  name: "Policies",
  props: {
    id: String,
    module: String,
  },
  mixins: [policyMixins],
  components: { VueEditor, draggable },
  data() {
    return {
      createLoading: false,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],

        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
    };
  },
  beforeMount() {
    this.policy.filters.id = this.id;
    this.policy.filters.module = this.module;
    this.getPolicies();
  },
  methods: {
    deletePolicy(policy) {
      let data = {};
      data.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      this.$http
        .post(`/policy/delete?id=${policy.id}`, data)
        .then(({ data }) => {
          data;

          this.getPolicies();
        });
    },
    updatePolicy(policy, index) {
      policy.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      policy.content_order = index;

      this.$http
        .post("/policy/update", policy)
        .then(({ data }) => {
          this.$message.success("Política atualizada!");
          data;
        })
        .catch(({ response }) => {
          response;
          this.$message.error("Não foi possível atualizar a política.");
        });
    },
    addPolicy() {
      this.createLoading = true;
      let thePolicy = {};
      thePolicy.module = this.module;
      thePolicy.module_id = this.id;
      thePolicy.content_order = this.policy.list.length;
      thePolicy.user_id = this.$store.state.userData.id;
      thePolicy.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };
      this.$http
        .post("/policy/create", thePolicy)
        .then(({ data }) => {
          data;
          this.createLoading = false;
          this.getPolicies();
        })
        .catch(({ response }) => {
          this.createLoading = false;
          response;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.field
  position: relative
  input
    border-radius: 3px
    border-color: #ddd !important
  label
    position: absolute
    top: -8px
    left: 8px
    z-index: 1
    font-size: 10px
    font-weight: 600
    background-color: #fff

.field.editor
  border: 1px solid #ddd
  border-radius: 3px
.field.show
   label
      position: absolute
      top: 14px
      left: 0px !important
</style>
