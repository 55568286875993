<template>
  <div>
    <a-row :gutter="30">
      <a-col
        :span="
          tempContract['reservation_policies_reference'] != undefined ? 14 : 24
        "
      >
        <a-row
          v-for="(policy, index) in reservationPoliciesRows"
          class="policy"
          type="flex"
          justify="center"
          :key="index"
          :gutter="[20, 0]"
        >
          <a-col :span="22">
            <a-form-item class="travel-input mb-40">
              <label class="filled">Título da política</label>
              <a-input
                placeholder="Título da política"
                v-model="policy.title"
                @change="updatePolicyRows"
              >
              </a-input>
            </a-form-item>

            <a-form-item class="travel-input">
              <label class="filled">Descrição da política</label>
              <vue-editor
                class="mt-10"
                v-model="policy.text"
                :editor-toolbar="customToolbar"
                placeholder="Digite o texto aqui"
                @blur="updatePolicyRows"
              ></vue-editor>
            </a-form-item>

            <a-checkbox
              class="mb-20"
              v-model="policy.showInContract"
              style="font-size: 12px; font-weight: 500; position: relative"
              @change="updatePolicyRows"
            >
              Marque para exibir no contrato
            </a-checkbox>
          </a-col>
          <a-col :span="2">
            <a-button
              v-if="reservationPoliciesRows.length == policy.id"
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              style="margin-top: 8px"
              @click="removePolicyRow(index)"
            >
            </a-button>
          </a-col>
        </a-row>
        <a-row class="mb-40 mt-20">
          <a-col :span="24">
            <center>
              <a-button
                @click="addPolicyRow(reservationPoliciesRows.length)"
                type="primary"
              >
                Adicionar política
              </a-button>
            </center>
          </a-col>
        </a-row>

        <!-- {{ reservationPoliciesRows }} -->
      </a-col>

      <a-col
        v-if="tempContract['reservation_policies_reference'] != undefined"
        :span="10"
      >
        <h2 style="font-size: 18px">
          Políticas Infotera
          <div
            style="
              font-size: 11px;
              margin-top: -5px;
              color: #aaa;
              font-weight: 400;
              color: orange;
            "
          >
            *Reescreva as políticas necessárias ao lado.
          </div>
        </h2>

        <textarea
          class="infotera-read"
          v-html="formatInfoteraPolicies()"
          readonly
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import replaceOnce from "replace-once";

export default {
  name: "ReservationPoliciesForm",
  components: {
    VueEditor,
  },
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
  },
  data() {
    return {
      reservationPoliciesRows: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],

        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean"],
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.reservationPoliciesRows = this.tempContract["reservation_policies"]
        ? JSON.parse(this.tempContract["reservation_policies"])
        : [];
    }, 100);
  },
  methods: {
    addPolicyRow(id) {
      this.reservationPoliciesRows.push({
        id: id + 1,
        title: "",
        text: "",
        showInContract: false,
      });
      this.updatePolicyRows();
    },
    removePolicyRow(index) {
      for (var i in this.reservationPoliciesRows) {
        if (i == index) {
          this.reservationPoliciesRows.splice(i, 1);
          break;
        }
      }
      this.updatePolicyRows();
    },
    formatText(p) {
      let paragraph = p.text;

      if (p.room != undefined) {
        paragraph = `${p.product} - Quarto ${p.room.number} : ${p.room.name}  ${p.text}`;
      }
      return paragraph;
    },
    updatePolicyRows() {
      let modified = this.reservationPoliciesRows.map((policy) => {
        return {
          ...policy,
          title: policy.title.replace(/"/g, '\\"'),
          text: policy.text.replace(/"/g, '\\"'),
        };
      });

      this.tempContract["reservation_policies"] = JSON.stringify(modified);
    },
    formatInfoteraPolicies() {
      let text = "";
      let find = ['":"', "title", '{"', '","text', '"},', '"}', "]", "\n"];
      let replace = ["", "", "", "\n\n", "\n\n", "", "", ""];

      if (this.tempContract["reservation_policies_reference"] != undefined) {
        text += replaceOnce(
          this.tempContract.reservation_policies_reference,
          find,
          replace,
          "g"
        );
      }
      return text.substring(1).replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="sass" scoped>
.infotera-read
    font-size: 11px
    width: 100%
    height: 700px
    outline-width: 0
    cursor: auto
    text-align: justify
    color: #000
    padding: 5px 10px
    border: 1px solid #eee
.infotera-read::-webkit-scrollbar
  width: 1px
.infotera-read::-webkit-scrollbar-thumb
  background: #ddd
</style>
