<template>
  <div>
    <a-row>
      <a-col v-if="$route.name != 'new'" class="t-box mb-20" :span="24">
        <div
          class="row border pl-15 hover c-pointer"
          @click="showPolicyModal = true"
        >
          <span class="txt">
            <a-row type="flex" justify="space-between">
              <a-col>
                <span class="img">
                  <img
                    src="@/assets/images/dashboard/contracts/policy.png"
                    alt="policy"
                  />
                </span>
                Políticas da reserva</a-col
              >
              <a-col>
                <a-tooltip v-if="validateAllContractFields.policyStatus.alert">
                  <template slot="title">
                    As políticas ainda não foram adicionadas
                  </template>
                  <img
                    class="c-pointer"
                    src="@/assets/images/dashboard/contracts/danger.png"
                    alt="img"
                    width="15"
                  /> </a-tooltip
              ></a-col>
            </a-row>
          </span>
        </div>

        <div
          v-if="$root.isAdmin()"
          class="row border pl-15 hover c-pointer"
          @click="showLogsModal = true"
        >
          <span class="img">
            <img
              src="@/assets/images/dashboard/contracts/logs.png"
              alt="logs"
            />
          </span>
          <span class="txt"> Histórico </span>
        </div>

        <div
          v-if="
            tempContract.id_infotera != '0' &&
            infoteraXMLCode &&
            $root.isAdmin()
          "
          class="row border pl-15 hover c-pointer"
          @click="showinfoteraXMLCodeModal = true"
        >
          <span class="img">
            <img src="@/assets/images/dashboard/contracts/xml.png" alt="xml" />
          </span>
          <span class="txt"> XML Infotera </span>
        </div>

        <div
          v-if="
            tempContract.id_infotera != '0' &&
            tempContract.infotera_json &&
            $root.isAdmin()
          "
          class="row border pl-15 hover c-pointer"
          @click="showInfoteraJSON = true"
        >
          <span class="img">
            <img
              src="@/assets/images/dashboard/contracts/json-file.png"
              alt="json"
              width="20"
              style="opacity: 0.6"
            />
          </span>
          <span class="txt"> JSON Infotera </span>
        </div>

        <div
          v-if="$root.isAdmin()"
          class="row no-border pl-15 hover c-pointer"
          @click="showSettingsModal = true"
        >
          <span class="img">
            <img
              src="@/assets/images/dashboard/contracts/configurations.png"
              alt="configurations"
            />
          </span>
          <span class="txt"> Configurações </span>
        </div>
      </a-col>
    </a-row>

    <a-drawer
      class="travel-drawer policies"
      placement="right"
      :width="
        tempContract['reservation_policies_reference'] != undefined
          ? '1200px'
          : '800px'
      "
      :closable="true"
      :visible="showPolicyModal"
      @close="onClosePoliciesDrawer"
    >
      <template slot="title"> Políticas da reserva </template>

      <div v-if="parseInt(contract.id) > 2262">
        <PoliciesModal
          v-if="showPolicyModal"
          :contract="contract"
          :tempContract="tempContract"
        />
      </div>
      <div v-else>
        <ReservationPoliciesForm
          v-if="showPolicyModal"
          :form="form"
          :contract="contract"
          :tempContract="tempContract"
        />
      </div>
    </a-drawer>

    <a-drawer
      placement="right"
      :width="$root.windowWidth > 900 ? '900px' : '100%'"
      class="travel-drawer"
      :closable="true"
      :visible="showLogsModal"
      @close="onCloseLogsDrawer"
    >
      <template slot="title"> Histórico </template>
      <div v-if="showLogsModal">
        <MultiLogsTimeline
          class="mt-10"
          :opportunityId="tempContract.opportunity_id"
          :contractId="tempContract.id"
          :saleId="tempContract.sale_id"
        />
      </div>
    </a-drawer>

    <a-drawer
      class="xml-viewer-drawer"
      placement="right"
      width="1120px"
      :closable="true"
      :visible="showinfoteraXMLCodeModal"
      @close="onCliseInfoteraXMLDrawer"
    >
      <template slot="title">
        <i class="fas fa-code"></i> XML INFOTERA - ID
        {{ contract.id_infotera }}
      </template>
      <div class="xml-viewer"></div>
    </a-drawer>

    <a-drawer
      placement="right"
      width="700px"
      :closable="true"
      :visible="showInfoteraJSON"
      @close="showInfoteraJSON = false"
    >
      <template slot="title">
        <i class="fas fa-code"></i> JSON INFOTERA - ID
        {{ contract.id_infotera }}
      </template>

      <json-viewer
        class="mt-10 mb-20"
        theme="json-viewer"
        :value="tempContract.infotera_json"
        :show-array-index="false"
        :expand-depth="2"
      ></json-viewer>
    </a-drawer>

    <a-drawer

      placement="right"
      width="740px"
      :closable="true"
      :visible="showSettingsModal"
      @close="onCloseSettingsDrawer"
    >
      <template slot="title"> CONFIGURAÇÕES</template>

      <ContractSettingsModal
        v-if="showSettingsModal"
        :form="form"
        :contract="contract"
        :tempContract="tempContract"
        :allowUserToEdit="allowUserToEdit"
        :disableInfoterId="false"
        @updateTempContractFromChild="updateTempContractFromChild"
      />
    </a-drawer>
  </div>
</template>

<script>
import PoliciesModal from "@/components/contracts/modals/PoliciesModal.vue";
import MultiLogsTimeline from "@/components/logs/MultiLogsTimeline.vue";
import ReservationPoliciesForm from "@/components/contracts/forms/ReservationPoliciesForm.vue";
import policyMixins from "@/mixins/policies/policyMixins";
import ContractSettingsModal from "@/components/contracts/modals/ContractSettingsModal.vue";

export default {
  name: "ContractSideMenuSection",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    validateAllContractFields: Object,
  },
  components: {
    ReservationPoliciesForm,
    PoliciesModal,
    MultiLogsTimeline,
    ContractSettingsModal,
  },
  mixins: [policyMixins],
  data() {
    return {
      allowUserToEdit: false,
      showPolicyModal: false,
      showLogsModal: false,
      showinfoteraXMLCodeModal: false,
      showSettingsModal: false,
      showInfoteraJSON: false,
      infoteraXMLCode: "",
      jsonInfotera: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.infoteraXMLCode = this.tempContract.infotera_xml;
      this.jsonInfotera = this.tempContract.infotera_json;

      this.allowUserToEdit = [1, "1", true].includes(
        this.tempContract.allow_user_edit
      )
        ? true
        : false;
    }, 500);
  },
  methods: {
    updateTempContractFromChild(newContract) {
      this.$emit("updateTempContractFromChild", newContract);
    },
    onClosePoliciesDrawer() {
      this.showPolicyModal = false;
      this.policy.filters.module = "contract";
      this.policy.filters.id = this.$route.params.id;
      this.getPolicies();
      this.$emit("allContractUpdates");

      setTimeout(() => {
        this.$emit("validatePolicyFields", this.policy.list);
      }, 500);
    },
    onCloseSettingsDrawer() {
      this.showSettingsModal = false;
      this.$emit("allContractUpdates");
    },
    onCliseInfoteraXMLDrawer() {
      this.showinfoteraXMLCodeModal = false;
      this.$emit("allContractUpdates");
    },
    onCloseLogsDrawer() {
      this.showLogsModal = false;
      this.$emit("allContractUpdates");
    },
  },
};
</script>

<style lang="sass">
.product-supplier-modal
  .ant-tabs-bar
    margin-bottom: 5px !important
  .ant-tabs-tab
    color: #aaa !important
    filter: grayscale(1)
    padding: 6px 16px
  .ant-tabs-tab-active
    filter: grayscale(0) !important
    color: #777 !important
  .ant-tabs-nav-wrap
    padding: 0 25px
  .ant-drawer-header
    border: 0 !important
  .ant-drawer-body
    padding: 0 !important

.miles-alert
  position: relative
  padding: 10px 10px 10px 60px !important
  background: #f3c7cc
  border-color: #f57383
  .ico
    position: absolute
    top: 8px
    left: 10px
  .ant-alert-message
    font-size: 14px
    font-weight: 500
    margin: 0 0 0px
    color: #df213a
  .ant-alert-description
    font-size: 11px
    margin: 0
    font-weight: 500
    line-height: 1.4
    color: #df213a

.contract-page
  background: #f1f1f2
  .tab-content
    h2.title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 600
      padding: 5px 8px
      margin-bottom: 20px !important
      letter-spacing: -0.5px
    h3.title
      display: block
      border-bottom: 1px solid #efefef
      font-size: 14px
      font-weight: 600
      padding: 5px 8px
      margin-bottom: 20px !important
    .ant-form-item
        height: 54px !important
        margin-bottom: 0 !important
    label
      position: absolute
      z-index: 4
      font-size: 10px
      font-weight: 500
      color: #ccc
      transition: .3s
      opacity: 0
      top: 13px
      left: 0
      line-height: 1
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      white-space: nowrap
    label.filled
      top: -16px
      opacity: 1
    .add-button
      font-size: 11px
      font-weight: 600
      color: #bbb
      border-color: #ddd
      padding: 2px 5px !important
      line-height: 4px
      height: 22px
      top: -3px
      position: relative
    .add-button.color
      border-color: #be4178
      color: #be4178
    .remove-button
      font-size: 11px
      font-weight: 600
      color: red
      border-color: red
      padding: 2px 5px !important
      line-height: 4px
      height: 20px
      top: -3px
      position: relative
</style>
<style lang="sass" scoped>
.json-viewer
  max-width: 100%
  background: #f5f5f5
  white-space: nowrap
  color: #333
  font-size: 14px
  border-radius: 8px
  font-family: Consolas, Menlo, Courier, monospace

.row
    padding: 5px 10px
    font-size: 13px
    font-weight: 500
    width: 314px
    transition: .3s
    max-width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &.hover:hover
        background: rgba(0,0,0,0.02)
        color: #ff8000
        .img
            -webkit-filter: grayscale(0)
            -webkit-filter: grayscale(0%)
    &.border
        border-bottom: 1px solid #d6d6d6
        padding: 10px 5px
    &.no-border
        border: 0
        padding: 10px  5px
    .img
        -webkit-filter: grayscale(1)
        -webkit-filter: grayscale(100%)
        width: 24px
        display: inline-block
        text-align: center

h1
    margin: 0
    padding: 0
    font-weight: 600
    letter-spacing: -2px
    .view
        color: #aaa
        font-size: 24px
    img
        margin-right: 5px
h2
    font-size: 14px
    font-weight: 400
    border-bottom: 1px solid #ddd
    padding: 0 0 10px 0 !important
    color: #59595b !important
</style>
