<template>
  <div>
    <a-row :gutter="30">
      <a-col
        :span="
          tempContract['reservation_policies_reference'] != undefined ? 14 : 24
        "
      >
        <Policies :module="'contract'" :id="tempContract.id" />
      </a-col>

      <a-col
        v-if="tempContract['reservation_policies_reference'] != undefined"
        :span="10"
      >
        <h2 style="font-size: 18px">
          Políticas Infotera
          <div
            style="
              font-size: 11px;
              margin-top: -5px;
              color: #aaa;
              font-weight: 400;
              color: orange;
            "
          >
            *Reescreva as políticas necessárias ao lado.
          </div>
        </h2>

        <textarea
          class="infotera-read"
          v-html="formatInfoteraPolicies()"
          readonly
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import replaceOnce from "replace-once";
import Policies from "../../general/Policies.vue";
export default {
  name: "PoliciesModal",
  components: { Policies },
  props: {
    contract: Object,
    tempContract: Object,
  },
  methods: {
    formatInfoteraPolicies() {
      let text = "";
      let find = ['":"', "title", '{"', '","text', '"},', '"}', "]", "\n"];
      let replace = ["", "", "", "\n\n", "\n\n", "", "", ""];

      if (this.tempContract["reservation_policies_reference"] != undefined) {
        text += replaceOnce(
          this.tempContract.reservation_policies_reference,
          find,
          replace,
          "g"
        );
      }
      return text.substring(1).replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="sass" scoped>
.infotera-read
    font-size: 11px
    width: 100%
    height: 700px
    outline-width: 0
    cursor: auto
    text-align: justify
    color: #000
    padding: 5px 10px
    border: 1px solid #eee
.infotera-read::-webkit-scrollbar
  width: 1px
.infotera-read::-webkit-scrollbar-thumb
  background: #ddd
</style>
